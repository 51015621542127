import { selectIsLoggedIn } from '@redux/features/authorization/authorization.selectors'
import { setIsOpenAuthorizationPopup } from '@redux/features/authorization/authorization.slice'
import { useAppDispatch, useAppSelector } from '@redux/hooks'

import { useUserId } from '@hooks/user/useUserId/useUserId'

const useOpenAuthPopup = () => {
  const userId = useUserId()()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const dispatch = useAppDispatch()

  const openAuthPopup = () => dispatch(setIsOpenAuthorizationPopup(true))

  return { isLoggedIn: !!userId || isLoggedIn, openAuthPopup }
}

export default useOpenAuthPopup
